<template>
  <div class="login">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">忘记密码</div>
    <img class="bg" src="../../assets/img/loginbg.png" alt="">

    <form class="login-card">
        <div class="catalog">
            <img src="../../assets/img/login_icon1.png" alt="">
            <input type="text" name="1" placeholder="用户名" v-model="userName">
        </div>
        <!-- <div v-else class="catalog">
            <img src="../../assets/img/login_icon1.png" alt="">
            <input type="text" name="2" placeholder="企业名称" v-model="userName">
        </div> -->
        <!-- <div class="catalog">
            <img src="@/assets/img/login_icon3.png" alt="">
            <input type="number" placeholder="输入手机号码" v-model="contactsPhone">
        </div> -->
        <div class="catalog">
            <img src="../../assets/img/login_icon3.png" alt="">
            <input style="width: 50%" type="text" placeholder="输入验证码" v-model="verificationCode">
            <!-- <div class="verify">获取验证码</div> -->
            <div v-show="showVerifyBtn===0" class="verify" @click="sendVerify()">发送验证码</div>
            <div v-show="showVerifyBtn===1" class="verify2">
                <van-count-down
                ref="countDown"
                :time="60000"
                format="ss"
                @finish="countDownFinish"
                />秒
             </div>
        </div>
        <div class="catalog">
            <img src="@/assets/img/login_icon2.png" alt="">
            <input type="password" placeholder="请输入新密码" v-model="password">
        </div>
        <div class="catalog">
            <img src="@/assets/img/login_icon2.png" alt="">
            <input type="password" placeholder="请再次输入密码" v-model="password2">
        </div> 
        <div class="login-btn" @click="submit()">确认</div>
        <div class="login-footer flex-between">
            <router-link to="/logins" class="blue">现在注册</router-link>
            <router-link to="/passwordForget" class="gray">忘记密码</router-link>
        </div>
    </form>

  </div>
</template>
<script>
import { reactive,ref,toRefs,toRaw  } from 'vue';
import { useRouter } from 'vue-router';
import { apiForgetSendVerificationCode,apiSaveForgetPwd} from '../../api/axios';
import { Toast } from 'vant';
export default {
   setup() {
        let router = useRouter(); //获取到值
        /*1：用户名 2：企业名称*/
        /* const type = ref('1');*/
        const countDown = ref(null);
        const state = reactive({
           showVerifyBtn: 0,
           password2: ''
       });
       const submitInfo = reactive({
           userName:'',
           password:'',
           contactsPhone: '',
           verificationCode: '',
           currentPassword: ''
       })
        // onMounted(() => {
        // console.log("获取到的参数", router.query.type);
        // type.value = router.query.type;
        // });

        //发送验证码
       const sendVerify = () => {
           if(submitInfo.userName === '') {
               Toast('请先输入用户名');
               return;
           }else {
                state.showVerifyBtn = 1;
                countDown.value.reset();//重置倒计时
        
                apiForgetSendVerificationCode({userName: submitInfo.userName}).then(res => {
                console.log(res,'返回的数据')                   
                    if(res.code == '1'){
                        submitInfo.contactsPhone = res.info.contactsPhone;
                        submitInfo.currentPassword = res.info.currentPassword;
                    }
                    else {
                    console.log(res.msg);
                    }
                });
                
           }   
       };
       //倒计时完成
       const countDownFinish = () => {
           state.showVerifyBtn = 0;           
       };
       let testPassword =/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{12,20}$/;
       //提交修改
       const submit = () =>{
           if(submitInfo.userName==='') {
               Toast('用户名格式错误')
               return;
           }
           if(!testPassword.test(submitInfo.password)) {
               Toast('密码需12-20个字符组成，且必须同时包含数字、小写字母、大写字母和特殊字符的三种及以上')
               return;
           }
           if(state.password2 != submitInfo.password) {
               Toast('两次密码输入不一致')
               return;
           }
           if(submitInfo.verificationCode === '') {
               Toast('请先输入验证码')
               return;
           }
           if(submitInfo.currentPassword === '') {
               Toast('请输入正确的验证码')
               return;
           }

        //    console.log(toRaw(submitInfo),992);
           apiSaveForgetPwd(toRaw(submitInfo)).then(res => {
            if(res.code == '1'){
                    Toast.success({
                        forbidClick: true,
                        message: '密码修改成功',
                    });
                    router.push({ name:'login',replace:true}); 
                }
                else {
                    Toast.fail(res.msg);
                }           
            }).catch(err=>{
                console.log(err,'请求错误')
            })
       }
       return {
           countDown,
           ...toRefs(state),
           ...toRefs(submitInfo),
           sendVerify,
           countDownFinish,
           submit
       }
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.login {
    width: 100vw;
    overflow-x: hidden;
    .navTitle {
        background: none;
    }
    img.bg {
        width: 100%;
        height: auto;
    }
    .login-card {
        margin: 0 14vw;
        margin-top: 15vw;
        .catalog {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,.05);
            padding: 2vw 0;
            img {
                width: 5vw;
                height: auto;
            }
            input {
                background: transparent;
                border: 0;
                margin-left: 2vw;
                flex: 1;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
            }
            .verify {
                color: #ff9700;
                padding: 1vw 2vw;
                border: 1px solid #ff9700;
                border-radius: 1.5vw;
                font-size: 4vw;
            }
            .verify2 {
                padding: 1vw 0;
                border: 1px solid #ff9700;
                border-radius: 1.5vw;
                font-size: 4vw;
                // color: #999;
                color: #ff9700;
                width: 25vw;
                display: flex;
                justify-content: center;
            }
            
        }
        .login-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 2vw 6vw;
            border-radius: 7vw;
            border: 0;
            margin-top: 8vw;
            text-align: center;
            letter-spacing: 2vw;
        }   
    
    }
    .login-footer {
        margin-top: 4vw;
        font-size: 4vw;
        .gray {
           color: #666666;
        }
        .blue {
            color: #3399ff
        }
    }
    
}
</style>
